export function setupOrganisationDropdownListeners() {
  const organisationDropdown = document.querySelector('#organisation_select') as any;

  if (!organisationDropdown) {
    return;
  }

  organisationDropdown.addEventListener('change', () => {
    const val = organisationDropdown.value;
    if (val === '0') {
      return;
    }
    const form = getForm(organisationDropdown);
    // @ts-ignore
    //for some weird reason value of organisation is not passed
    //for workaround I am adding hidden field and passing value on form submit
    document.getElementById('selected_org_id').value = organisationDropdown.value;
    form.submit();
  });
}

function getForm(element) {
  do {
    let parent = element.parentNode;
    if (parent.nodeName === 'FORM') {
      return parent;
    }
  } while (parent !== null);

  return null;
}

export function handleHeaderSidenav() {
  var header = document.querySelector('cbar-header') as any;

  if (!header || !header.drawer || !header.persistentDrawer) {
    return
  }

  if (header) {
    var appDrawerToggledHandler = function () {
      var marginLeft = 0;

      if (header.drawer.opened) {
        marginLeft = header.drawer.getWidth();
      }

      document.querySelectorAll('.wrapper > section, .wrapper > footer').forEach((element: any) => {
        element.style.marginLeft = `${marginLeft}px`;
      })
    };

    header.addEventListener('app-drawer-toggled', appDrawerToggledHandler);

    header.addEventListener('search-submitted', function (e) {
      if (e.detail) {
        // @ts-ignore
        window.location = '/admin/searchentities?search_string=' + encodeURI(e.detail);
      }
    });

    window.customElements.whenDefined('cbar-header').then(() => {
      // Wait for cbar-header to be ready before showing drawer to ensure that
      // non-native Shadow DOM browsers do not flash the un-styled drawer
      let sidenavContainer = header.querySelector('.aside-inner');

      if(sidenavContainer) {
        sidenavContainer.style.display = 'block';
      }

      // Fire it once manually
      appDrawerToggledHandler();
    });
  }
}